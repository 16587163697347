import * as React from 'react';
import * as styles from './contact-us.module.css';

import {
	Box,
	Button,
	HStack,
	Input,
	Link,
	Radio,
	RadioGroup,
	Stack,
	Text,
	Textarea,
	VStack,
	useToast,
} from '@chakra-ui/react';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { gql, useMutation } from '@apollo/client';

import Layout from '../components/Base/Layout';
import { SEO } from '../components/Base/Seo';
import useAuth from '../components/useAuth';

const SUBMIT_CONTACT_FORM = gql`
	mutation submitContactFormCF(
		$name: String!
		$email: String!
		$radioChoice: String!
		$message: String!
	) {
		submitContactFormCF(
			input: {
				submissionId: "622"
				email: $email
				name: $name
				radioChoice: $radioChoice
				message: $message
			}
		) {
			name
			email
			successMessage
			errors
		}
	}
`;

const ContactUs: FunctionComponent = () => {
	const { isBankUser, isOtherBankUser, isSyntechUser, isCloudTalkUser, isCyprusMerchant } =
		useAuth();
	const [submitContactForm, { loading, error }] =
		useMutation(SUBMIT_CONTACT_FORM);
	var toast = useToast();

	const [formValues, setFormValues] = useState({
		fullName: '',
		email: '',
		radioChoice: 'questions',
		message: '',
	});

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setFormValues((prevValues) => ({
			...prevValues,
			[name]: value,
		}));
	};

	const handleRadioChange = (value: string) => {
		setFormValues((prevValues) => ({
			...prevValues,
			radioChoice: value,
		}));
	};

	const handleFormData = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		// Use the submitContactForm mutation to send the data to the server
		try {
			const { data } = await submitContactForm({
				variables: {
					submissionId: '622',
					name: formValues.fullName,
					email: formValues.email,
					message: formValues.message,
					radioChoice: formValues.radioChoice,
				},
			});

			if (!error) {
				toast({
					status: 'success',
					title: 'Message was sent!',
				});
			} else {
				toast({
					status: 'error',
					title: 'There was an error. Please try again later.',
				});
			}
		} catch (error) {
			console.error('Error submitting form:', error);
		}
	};

	return (
		<>
			<Layout>
				<form onSubmit={handleFormData}>
					<div className={styles.contactus}>
						<div className={styles.contactusslot}>
							<div className={styles.titlebox}>
								<div className={styles.divider} />
								<div className={styles.title}>
									<h1 className={styles.needHelpGet}>
										Need help? Get in touch
									</h1>
								</div>
							</div>
							<div className={styles.contactsection}>
								<div className={styles.contactform}>
									<div className={styles.inputs}>
										<div className={styles.inputtextStyle1}>
											<Text className={styles.yourName}>Your name</Text>
											<Input
												id='fullName'
												name='fullName'
												className={styles.input}
												border='1px solid var(--gray-800)'
												borderRadius='var(--br-4xs)'
												variant='outline'
												textColor='#1a202c'
												focusBorderColor='#dca672'
												_hover={{ border: '1px solid #dca672' }}
												type='text'
												minLength={4}
												required
												value={formValues.fullName}
												onChange={handleInputChange}
											/>
										</div>
										<div className={styles.inputtextStyle1}>
											<Text className={styles.yourName}>Your email</Text>
											<Input
												id='input'
												name='email'
												className={styles.input}
												variant='outline'
												border='1px solid var(--gray-800)'
												borderRadius='var(--br-4xs)'
												_hover={{ border: '1px solid #dca672' }}
												textColor='#1a202c'
												focusBorderColor='#dca672'
												type='email'
												required
												value={formValues.email}
												onChange={handleInputChange}
											/>
											<span className={styles.errorMsg}></span>
										</div>
									</div>
									<VStack
										w='100%'
										alignItems='flexStart'>
										<Text>Purpose of contact</Text>
										<RadioGroup
											value={formValues.radioChoice}
											onChange={(value) => handleRadioChange(value)}>
											<Stack direction='row'>
												<Radio
													value='questions'
													colorScheme='orange'>
													Questions
												</Radio>
												<Radio
													value='feedback'
													colorScheme='orange'>
													Feedback
												</Radio>
											</Stack>
										</RadioGroup>
									</VStack>
									<div className={styles.inputtextStyle4}>
										<Text className={styles.yourName}>Message</Text>
										<Textarea
											id='message'
											name='message'
											className={styles.textbox}
											border='1px solid var(--gray-800)'
											borderRadius='var(--br-4xs)'
											variant='outline'
											textColor='#1a202c'
											focusBorderColor='#dca672'
											_hover={{ border: '1px solid #dca672' }}
											required
											value={formValues.message}
											//@ts-ignore
											onChange={handleInputChange}
										/>
									</div>
									<button
										className={styles.darkButton}
										type='submit'>
										<Text
											fontWeight={700}
											className={styles.label}>
											{' '}
											{loading ? 'SENDING' : 'SEND MESSAGE'}
										</Text>
									</button>
								</div>
								<div className={styles.contactinformation}>
									<div className={styles.infobox}>
										<Text className={styles.infotitle}>
											<b
												className={
													styles.customerService
												}>{`Customer Service `}</b>
										</Text>
										<Text className={styles.customerServiceHours}>
											Customer service hours of operation: MON - FRI 9AM to 12AM
											EST
											<br></br>SAT - SUN 9AM to 6PM EST
										</Text>
									</div>
									<div className={styles.contact}>
										<div className={styles.contactrow}>
											<div className={styles.lighttel}>
												<div className={styles.lighttel}>
													<div className={styles.icons1}>
														<img
															className={styles.phoneIcon}
															alt='phone13'
															src='/phone13.svg'
														/>
													</div>
													<div className={styles.text}>
														<Text
															fontWeight={700}
															className={styles.customerService}>
															Toll Free
														</Text>
														<a
															className={styles.a}
															href='tel:+18663432413'>
															+1-866-343-2413
														</a>
														<a
															className={styles.a}
															href='tel:+1-866-485-6820'>
															+1-866-485-6820
														</a>
													</div>
												</div>
											</div>
											<div className={styles.lighttel}>
												<div className={styles.icons1}>
													<img
														className={styles.phoneIcon}
														alt='phone13'
														src='/phone13.svg'
													/>
												</div>
												<div className={styles.text}>
													<Text
														fontWeight={700}
														className={styles.customerService}>
														FAX
													</Text>
													<a
														className={styles.a}
														href='tel:+18887932862'>
														+1-888-793-2862
													</a>
												</div>
											</div>
											<div className={styles.lighttel}>
												<div className={styles.icons2}>
													<img
														className={styles.phoneIcon}
														alt='mail13'
														src='/mail13.svg'
													/>
												</div>
												<div className={styles.text}>
													<Text
														fontWeight={700}
														className={styles.customerService}>
														Mail
													</Text>
													<a
														className={styles.a}
														href='mailto:info@doctormedica.co'
														target='_blank'
														rel='noopener'>
														info@doctormedica.co
													</a>
												</div>
											</div>
											<div className={styles.lighttel}>
												<div className={styles.icons2}>
													<img
														className={styles.phoneIcon}
														alt='mail13'
														src='/mail13.svg'
													/>
												</div>
												<div className={styles.text}>
													<Text
														fontWeight={700}
														className={styles.customerService}>
														Share feedback at:
													</Text>
													<a
														className={styles.a}
														href='mailto:care@doctormedica.co'
														target='_blank'
														rel='noopener'>
														care@doctormedica.co
													</a>
												</div>
											</div>
											<Link
												href='https://talktodoctormedica.youcanbook.me/'
												target='_blank'
												rel='noopener'
												className={styles.darkButton}>
												<Text
													fontWeight={700}
													className={styles.label}>
													Book a Consultation
												</Text>
											</Link>
										</div>
										<div className={styles.infobox}>
											{isBankUser ? (
												<>
													<div className={styles.infotitle}>
														<Text
															className={
																styles.customerService
															}>{`STRREAM DIRECT MARKETING LTD  `}</Text>
													</div>
													<Text className={styles.customerServiceHours}>
														Registration Number 2426117 <br /> Registered office
														2540 Matheson Blvd East, Unit 202, Mississauga, ON
														L4W4Z2, Canada
													</Text>
												</>
											) : null}

											{isOtherBankUser ? (
												<>
													<div className={styles.infotitle}>
														<Text
															className={
																styles.customerService
															}>{`STRREAM DIRECT MARKETING LTD.`}</Text>
													</div>
													<Text className={styles.customerServiceHours}>
														30 Eglinton Ave West, Unit 710 <br /> Mississauga,
														ON <br /> Canada, L5R3E7
													</Text>
												</>
											) : null}

											{isSyntechUser ? (
												<>
													<div className={styles.infotitle}>
														<Text
															className={
																styles.customerService
															}>{`Syntech Solutions Kft.`}</Text>
													</div>
													<Text className={styles.customerServiceHours}>
														Kinizsi Utca 20. Fz. 1 Ajto
														<br /> Hungary, Budapest, 1203
													</Text>
												</>
											) : null}

											{isCyprusMerchant ? (
												<>
													<div className={styles.infotitle}>
														<Text
															className={
																styles.customerService
															}>{`Strream Direct Marketing (Cyprus) Limited`}</Text>
													</div>
													<Text className={styles.customerServiceHours}>
														Registration Number: HE 430209 <br /> Address:
														Andrea Miaouli 2A, 4006 Limassol, Cyprus
													</Text>
												</>
											) : null}

											{isCloudTalkUser ? (
												<Box pt='16px'>
													<div className={styles.infotitle}>
														<Text
															fontWeight={700}
															className={
																styles.customerService
															}>{`STRREAM DIRECT MARKETING EUROPE `}</Text>
													</div>
													<Text className={styles.customerServiceHours}>
														Company Address: Andrea Miaouli, 2A, 4006, Limassol,
														Cyprus.
														<br />
														Company registration number: ΗΕ 430209
														<br />
														Website:{' '}
														<Link
															href='http://www.strreamdirect.com/'
															color='#D28C49'>
															{' '}
															www.strreamdirect.com{' '}
														</Link>
														<br />
														Director Name: Eleftherios Eleftheriou
														<br />
														Company Email address:{' '}
														<Link
															href='mailto:europe.office@strreamdirect.com'
															color='#D28C49'>
															{' '}
															europe.office@strreamdirect.com{' '}
														</Link>
														<br />
													</Text>
												</Box>
											) : null}
										</div>
										<Text className={styles.contactrow}></Text>
									</div>
								</div>
							</div>
						</div>
					</div>
				</form>
			</Layout>
		</>
	);
};

export const Head = () => (
	<SEO
		title='Get in touch with us! We are here to help! | Contact Us'
		description='Feel free to contact us with any questions or concerns you may have. Here you can find our full contact information'
	/>
);

export default ContactUs;
