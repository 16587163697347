// extracted by mini-css-extract-plugin
export var a = "contact-us-module--a--62095";
export var auAustralia = "contact-us-module--auAustralia--95d28";
export var bulkdoctormedicaco = "contact-us-module--bulkdoctormedicaco--8c6b8";
export var contact = "contact-us-module--contact--fccd7";
export var contactform = "contact-us-module--contactform--6299b";
export var contactinformation = "contact-us-module--contactinformation--6b855";
export var contactrow = "contact-us-module--contactrow--4f857";
export var contactsection = "contact-us-module--contactsection--4fe4e";
export var contactus = "contact-us-module--contactus--5a517";
export var contactusslot = "contact-us-module--contactusslot--83d12";
export var customerService = "contact-us-module--customerService--88b7a";
export var customerServiceHours = "contact-us-module--customerServiceHours--2aeec";
export var darkButton = "contact-us-module--darkButton--37671";
export var div = "contact-us-module--div--e37d6";
export var divider = "contact-us-module--divider--a6579";
export var errorMsg = "contact-us-module--errorMsg--344bf";
export var forInquiriesRegardingContainer = "contact-us-module--forInquiriesRegardingContainer--06838";
export var gbUkmUnitedKingdom = "contact-us-module--gbUkmUnitedKingdom--e0c23";
export var icons = "contact-us-module--icons--dbc06";
export var icons1 = "contact-us-module--icons1--7be4b";
export var icons2 = "contact-us-module--icons2--203d2";
export var icons4 = "contact-us-module--icons4--dc0c4";
export var infobox = "contact-us-module--infobox--da9ec";
export var infobox1 = "contact-us-module--infobox1--6ff75";
export var infotitle = "contact-us-module--infotitle--8220d";
export var input = "contact-us-module--input--eb7f1";
export var inputs = "contact-us-module--inputs--fcb89";
export var inputtextStyle1 = "contact-us-module--inputtextStyle1--8dde4";
export var inputtextStyle4 = "contact-us-module--inputtextStyle4--bad7e";
export var label = "contact-us-module--label--42ed5";
export var lighttel = "contact-us-module--lighttel--5b62e";
export var needHelpGet = "contact-us-module--needHelpGet--54866";
export var phoneIcon = "contact-us-module--phoneIcon--fd1ca";
export var text = "contact-us-module--text--c13dd";
export var text4 = "contact-us-module--text4--4fcbf";
export var textbox = "contact-us-module--textbox--01bf4";
export var title = "contact-us-module--title--f88b8";
export var titlebox = "contact-us-module--titlebox--de12d";
export var yourName = "contact-us-module--yourName--1addd";